/* Customizations to the designer theme should be defined here */

.p-autocomplete-item {
  white-space: normal !important;
  word-break: break-word !important;
}

/* Menubar */
.p-menubar {
  border: 0
}
.p-datatable .p-datatable-tbody > tr > td {
  font-weight: 300;
  word-break: break-word;
}

.p-sidebar {
  padding-left: 0; padding-right: 0;
}

.p-sidebar .p-sidebar-close {
  margin-right: 10px;
  color: black;
}
.p-dialog .p-dialog-content {
  background: #ffffff;
  padding: 0.5rem;
  height: 100%;
}
.p-dialog .p-dialog-footer {
  border-top: 1px solid #dee2e6;
  background: #ffffff;
  color: #000000;
  padding: 0.5rem;
  text-align: right;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #ffffff;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  color: black;
}
.p-panel .p-panel-header {
  border: 0;
  padding: 0;
  background: none;
  color: #ffff;
}
.p-panel .p-panel-content {
  padding: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background: #ffffff;
  color: #000000;
}

.p-panel .p-panel-footer {
  padding: 0.5rem 1rem;
  border: none;
  background: #ffffff;
  color: #000000;
  border-top: 0 none;
}


/** Change logo color on menubar **/
@media screen and (max-width: 960px) {
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: black;
    margin-right: 0.5rem;
  }
}
.p-datatable .p-datatable-header {
  padding-top: 7px;
  padding-bottom: 7px;

}

