// Theme Specific Variables
$primaryColor: #16214d;
$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #ffffff;

// Mandatory Designer Variables
@import 'variables/general';
@import 'variables/form';
@import 'variables/button';
@import 'variables/panel';
@import 'variables/data';
@import 'variables/overlay';
@import 'variables/message';
@import 'variables/menu';
@import 'variables/media';
@import 'variables/misc';

:root {
    --surface-a:#ffffff;
    --surface-b:#f8f9fa;
    --surface-c:#e9ecef;
    --surface-d:#dee2e6;
    --surface-e:#ffffff;
    --surface-f:#ffffff;
    --text-color:#495057;
    --text-color-secondary:#6c757d;
    --primary-color:#16214d;
    --primary-color-text:#ffffff;
    --font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
