/* You can add global styles to this file, and also import other style files */

$sm:640px;
$md:720px;
$lg:960px;
$xl:1080px;

// Colors
$fxPrimary: #16214D !default;
$fxSecondary: #e84d14 !default;
$fxSuccess: #73BF21 !default;
$fxInfo:    #4A90E2 !default;
$fxWarning: #F5A623 !default;
$fxDanger:  #D0021B !default;
$fxDisabled: #D3D3D3 !default;

.warning-bg {
  background-color: $fxWarning;
}

.success-bg {
  background-color: $fxSuccess;
}

/** Icons
  Save: pi pi-bookmark,
  Cancel: pi pi-times
  Archive: far fa-archive,
  Add/New Item: pi pi-plus-circle,
  Add All: pi pi-plus,
  Remove: pi pi-trash,
  Complete: pi pi-check-circle,
  Out off order: far fa-times-circle
  Move: far fa-retweet
  Edit: pi pi-cog
  EditV2: far fa-edit
  Read: pi pi-book
  Evaluation: far fa-clipboard-check
  Exposure: far fa-exclamation-triangle
  Roles: far fa-clipboard-user
  Undo: pi pi-replay
  Number of Deviations: far fa-exclamation-triangle
  Comment: pi pi-comments
  Copy: far fa-clone
  Notification: far fa-bell
  Notification-on: far fa-bell-on
  Notification-off: far fa-bell-slash
  ConfirmDialog: pi pi-exclamation-circle
  ChecklistStatus: far fa-clock
  Graduation-cap: far fa-graduation-cap
  file: far fa-file
  download: far fa-cloud-download
  warehouse: far fa-warehouse
  user: far fa-user
  Send sms: far fa-sms
  comments: far fa-comments
  Leader: far fa-users-crown

 */

// Max width
.max-width-400{
  max-width: 400px;
}

.p-dropdown-item{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.breakAll {
  word-break: break-all;
}

.breakDots {
  white-space: nowrap;
  word-break: break-word;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breakWord {
  word-break: break-word;
  height: 3rem;
}

.text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mockHeader {
  border-bottom: 1px solid #e9ecef !important;
  font-weight: 600 !important;
  color: #000000 !important;
  background: #f8f9fa !important;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}
.mockRow {
  background: #ffffff;
  color: #000000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
  outline-color: #333348 !important;
  box-sizing: border-box !important;
}
.box {
  margin: 0.2rem;
  border-radius: 6px;
}

.banner {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

// Layout
.transparent {
  border: transparent ;
  background: transparent;
  box-shadow: none;
  width: 100vw;
}

// Modal Size
.max-size-dialog{
  max-width: 100%;
  max-height: 100%;
  width: 600px;
  height: 850px;
}
.max-width-dialog{
  max-width: 100%;
  width: 600px;
}
.min-width-dialog{
  max-width: 100%;
  width: 300px;
}
.max-size-width-dialog{
  max-width: 100%;
  width: 600px;
}
.small-size-dialog{
  max-width: 100%;
  max-height: 100%;
  width: 500px;
  height: 500px;
}
.max-size-dialog-short{
  max-width: 100%;
  max-height: 100%;
  width: 600px;
  height: 380px;
}
.max-size-dialog-short-2{
  max-width: 100%;
  width: 600px;
  height: 600px;
}
.max-size-sm-dialog-long{
  max-width: 100%;
  max-height: 100%;
  width: 500px;
  height: 700px !important;
}
.max-size-sm-dialog {
  max-width: 100%;
  max-height: 100%;
  width: 500px;
  height: 350px !important;
}
.max-size-xs-sm-dialog {
  max-width: 100%;
  max-height: 100%;
  width: 500px;
  height: 275px !important;
}
h2{
  margin-top: 7px;
  margin-bottom: 5px;
}

// Scroll Fix Hammer

.pan-y-scroll{
  touch-action: pan-y !important;
}

.width-100 {
  width: 100% !important;
}

.height-100 {
  height: 100% !important;
}

.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
.float-bottom {
  float: bottom !important;
  margin-bottom: 3px;
}

.no-margin-padding {
  margin: 0;
  padding: 0;
}
.footer{
  position: fixed;
  bottom: 15px;
  right: 15px
}

// Colors
.primary-color{
  color: #16214D !important;
}

.disabled{
  background: lightgrey;
}

// Text Colors

.text-muted { color: #636c72 !important; }


// MOBILE Only
@media only screen and (max-width: 400px) {
  .customToast {
    display: block;
    width: 300px !important;
  }
}

.p-overlaypanel {
  color: white;
}

.p-card-content {
  padding: 0
}
