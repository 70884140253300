.p-sidebar {
    background: $overlayContentBg;
    color: $panelContentTextColor;
    padding: $panelContentPadding;
    border: $overlayContentBorder;
    box-shadow: $overlayContainerShadow;

    .p-sidebar-close {
        @include action-icon();
    }
}

.p-sidebar-mask.p-component-overlay {
    background: $maskBg;
}
